<template>
  <div id="login">
    <dv-full-screen-container class="bg">
      <div class="login-box">
        <dv-border-box-10>
        <!--
        v-show="index != 1"
        -->
          <el-form 
            ref="ruleForm"
            class="w-100 h-100 d-flex flex-column jc-center ai-center"
            :model="model"
            :rules="rules">
            <span class="title">{{screenType[index].name}}</span>
            <el-form-item prop="username">
              <el-input class="big-input" placeholder="请输入用户名" v-model="model.username"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input class="big-input" placeholder="请输入密码" v-model="model.password" show-password></el-input>
            </el-form-item>
            <div class="d-flex big-input" v-if="index===0&&isAdmin">
              <el-form-item class="flex-1 mr-4" prop="code">
                <el-input class="w-100" placeholder="请输入验证码" v-model="model.code"></el-input>
              </el-form-item>
              <div class="flex-1" @click="refreshCode" >
                <img class="w-100" style="height: 0.55rem;" :src="`https://admin.fhj.thesetech.com/fhjiot-admin/captcha?uuid=${uuid}`" />
              </div>
            </div>
            <el-form-item>
              <div class="login-btn" @click="login">登录</div>
            </el-form-item>
          </el-form>
          <div v-show="index === 1" class="w-100 h-100 d-flex jc-center ai-center"></div>
        </dv-border-box-10>
         <!-- 
         <div class="choose-box d-flex flex-column ai-end">
          <div class="choose-btn mb-1" 
            :class="{active:index === i}" 
            v-for="(item, i) in screenType" 
            :key="i"
            @click="changeScreen(i)">
            {{item.name}}
          </div>
        </div> 
        -->
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      model: {
        username: '',
        password: '',
        code: ''
      },
      rules: {
        username: [
          {required: true, message: '必填项不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '必填项不能为空', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '必填项不能为空', trigger: 'blur'}
        ]
      },
      uuid: '',
      index: 1,
      isAdmin:false,
      screenType: [
        {name: '国安达-防护家智能应急监测平台', url: 'https://admin.fhj.thesetech.com'},
        // {name: '社区可视化大屏', url: 'https://service.fhj.thesetech.com'},
        {name: '区域可视化平台', url: 'https://dash.api.fhj119.com'},
      ]
    };
  },
  components: {},
  created () {
    // this.index= 2;
    document.title = this.screenType[this.index].name;
    if(this.index==0){
      // this.isAdmin = true;
      localStorage.setItem('baseUrl', this.screenType[this.index].url);
      this.index= 0;document.title = '国安达-防护家智能应急监测平台';
      this.refreshCode();
    }
    localStorage.setItem('baseUrl', this.screenType[this.index].url);
  },
  mounted () {},
  destroyed () {},
  methods: {
    changeScreen (i) {
      this.index = i;
      localStorage.setItem('baseUrl', this.screenType[this.index].url);
      if(i==0){
        this.isAdmin = true;
         this.refreshCode();
      }
    },
    generateUUID () {
      var d = new Date().getTime();
      if(window.performance && typeof window.performance.now === "function"){
          d += performance.now();
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
      });
      return uuid;
    },
    refreshCode () {
      this.uuid = this.generateUUID();
    },
    dashLogin(){
      let dashUrl = this.screenType[this.index].url;
        this.$post(dashUrl+"/api/regional/regionaluser/loginByPassword", {
            password: this.model.password,
            username: this.model.username,
            //  password: '123456',
            // username: 'fjtest',
          },{},true).then(res=> {
            console.log("R",res)
            let dashUser = res.data;
              localStorage.setItem('dashToken',dashUser.token);
              localStorage.setItem('dashUser',JSON.stringify(dashUser));  
              this.$store.state.dashUrl = dashUrl;
              this.$store.state.dashUser = dashUser;
              localStorage.setItem('dashUrl', dashUrl);
              localStorage.setItem('baseUrl', dashUrl);
              setTimeout(()=>{
                this.$router.push({path: '/home'});
                this.initFullScreen();  
              },100)
                            
        })
    },
    login () {
      if(this.index!=0){
        this.dashLogin();
        return;
      }
      // if(this.index||1==1){
      //   return;
      // }

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
         let  loginUrl = this.index==2?this.$dash.login:this.$apis.adminLogin;
          this.$post(loginUrl, {
            captcha: this.model.code,
            password: this.model.password,
            username: this.model.username,
            // password: '',
            // username: 'admin',
            uuid: this.uuid
          }).then(res => {
            console.log("R",res)
            if (res&&res.code === 0) {
              // this.initFullScreen();
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                if(this.index==2){
                    this.$router.push({path: '/dash'});
                    return ;
                }
                 this.$router.push({path: '/home'});
            } else {
              this.refreshCode();
            }
          });
        }
      });
    },
    initFullScreen(){
      let element = document.documentElement;
      if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
    },
  }
};
</script>

<style lang="scss" scoped>
#login {
  color: #d3d6dd;
  background-color: #000000;
  width: 100%;
  height: 100vh;
  .bg {
    padding: 0.2rem;
    background-image: url("../assets/pageBg.png");
    background-size: cover;
    background-position: center center;
  }
  .login-box {
    position: fixed;
    width: 6.5rem;
    height: 5.5rem;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    .title {
      margin-bottom: 0.4rem;
      font-size: 0.4rem;
    }
    .big-input {
      width: 5rem;
    }
    ::v-deep.el-input__inner {
      background-color: rgba(86, 138, 234, .3);
      border-color: #568aea;
      color: #d3d6dd;
    }
    ::v-deep.input__inner:focus {
      border-color: #568aea;
    }
    .login-btn {
      width: 5rem;
      height: 0.55rem;
      background-color: #568aea;
      border-radius: 0.1rem;
      text-align: center;
      font-size: 0.25rem;
      line-height: 0.55rem;
    }
    .choose-box {
      position: absolute;
      width: 2rem;
      top: 1rem; left: -2rem;
      .choose-btn {
        cursor: pointer;
        width: 1.8rem;
        height: 0.5rem;
        background-color: rgba(86, 138, 234, .3);
        text-align: center;
        font-size: 0.2rem;
        line-height: 0.5rem;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
      }
      .active {
        width: 2rem;
        background-color: #568aea;
      }
    }
  }
}
</style>